import React from 'react';

// Translation data for Help Page
const translations = {
  en: {
    title: "Help",
    message: "If you need any help or have any questions, send us a message at ",
    subMessage: "Our support team is available 24/7 to assist you with any issues, concerns, or inquiries. Don't hesitate to reach out!",
    buttonText: "Send us a message",
    email: "appsetofficial@gmail.com",
  },
  pl: {
    title: "Pomoc",
    message: "Jeśli potrzebujesz pomocy lub masz pytania, wyślij nam wiadomość na adres ",
    subMessage: "Nasz zespół wsparcia jest dostępny 24/7, aby pomóc Ci w każdej sprawie. Nie wahaj się z nami skontaktować!",
    buttonText: "Wyślij wiadomość",
    email: "appsetofficial@gmail.com",
  },
};

function Help({ language }) {
  const t = translations[language];  // Access translations based on the selected language

  const handleSendEmail = () => {
    window.open('https://mail.google.com/mail/u/0/#inbox?compose=new', '_blank');
  };

  return (
    <div className="main-container">
      {/* Background stars */}
      <div className="dot-container"></div>

      {/* Navigation Bar */}
      <div className="navbar">
        <div className="navbar-left">
          <a href="/" className="navbar-title">Appset</a>
        </div>
        <div className="navbar-right">
          <a href="/help" className="navbar-link">{t.title}</a>
          <a href="/feedback" className="navbar-link">Feedback</a>
        </div>
      </div>

      {/* Help Page Content inside a box */}
      <div className="help-container">
        <h1 className="header">{t.title}</h1>
        <p>
          {t.message}
          <strong>{t.email}</strong>
        </p>
        <p>{t.subMessage}</p>

        {/* Button to open Gmail */}
        <a href="mailto:appsetofficial@gmail.com" className="send-message-button">{t.buttonText}</a>
      </div>
    </div>
  );
}

export default Help;
