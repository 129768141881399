import React from 'react';

const translations = {
  en: {
    title: "Send Us Your Feedback",
    message: "If you have any feedback or suggestions, feel free to send us a message.",
    contact: "Contact us at:",
    buttonText: "Send Us a Message",
  },
  pl: {
    title: "Wyślij nam swoją opinię",
    message: "Jeśli masz jakiekolwiek opinie lub sugestie, chętnie je usłyszymy.",
    contact: "Skontaktuj się z nami pod adresem:",
    buttonText: "Wyślij wiadomość",
  },
};

function FeedbackPage({ language }) {
  const t = translations[language];

  return (
    <div className="main-container">
      {/* Background stars */}
      <div className="dot-container"></div>
      
      {/* Navigation Bar */}
      <div className="navbar">
        <div className="navbar-left">
          <a href="/" className="navbar-title">Appset</a>
        </div>
        <div className="navbar-right">
          <a href="/help" className="navbar-link">Help</a>
          <a href="/feedback" className="navbar-link">Feedback</a>
        </div>
      </div>

      {/* Feedback Page Content inside a box */}
      <div className="help-container">
        <h1 className="header">{t.title}</h1>
        <p>{t.message}</p>
        <p>{t.contact} <strong>appsetofficial@gmail.com</strong></p>

        {/* Button to open Gmail */}
        <a href="mailto:appsetofficial@gmail.com" className="send-message-button">{t.buttonText}</a>
      </div>
    </div>
  );
}

export default FeedbackPage;
